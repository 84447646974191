<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{active : modelValue}">
        <div class="global_setting">
            <div class="tabs_content">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
                <div class="stat_container">
                    <div class="section_header mt-5 mb-4">
                        <h2>Body Composition <span class="tag">{{ProgressScenarioList[selectScenario].title}}</span></h2>
                        <div class="optionDrops contacts-tabs" @click="progressDuration = !progressDuration" v-click-outside="closeProgressDuration">
                            {{ selectProgressFilter }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="progressDuration ? 'active' : ''">
                                <ul>
                                    <li v-for="(duration, d) of progressFilterDuration" :key="d" @click="selectProgressFilter = duration;">{{ duration }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="optionDrops" @click="openFilter = !openFilter;" v-click-outside="closeOpenFilter">
                            Filter
                            <div class="dropdown_wpr progress_filter_bar" :class="openFilter ? 'active' : ''">
                                <div class="scenario_box" @click.stop="scenarioDropdown = !scenarioDropdown;" v-click-outside="closeScenarioDropdown">
                                    {{ProgressScenarioList[selectScenario].title}}<i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="scenarioDropdown ? 'active' : ''">
                                        <ul>
                                            <li v-for="(scenario, s) in ProgressScenarioList" :key="s" @click="selectScenario = s;">{{scenario.title}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="category_wpr">
                                    <div class="category_grp active" v-for="(progress, p) of ProgressScenarioList[selectScenario].categories" :key="p">
                                        <h4>{{progress.group_title}}</h4>
                                        <ul>
                                            <li v-for="(item, i) of progress.items" :key="i" @click.stop="item.is_selected = !item.is_selected">
                                                <label :for="`filter-${i}`" class="checkbox">
                                                    <span :class="{'active' : item.is_selected}"><i class="fas fa-check"></i></span>
                                                    <h5>{{ item.name }}</h5>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="report_card">
                        <div class="header">
                            <div class="user_info">
                                <img :src="user.profile_pic" alt="">
                                <div>
                                    <h4>{{ user.full_name }}</h4>
                                    <h5>Started Tracking &nbsp;<span>&#123;&#123;Oct 21, 2022&#125;&#125;</span></h5>
                                </div>
                            </div>
                            <ul class="total_progress" @click="scrollingTo(`progress_history`)">
                                <li>
                                    <h3>24<span>Days</span></h3>
                                    <label class="completed">Completed</label>
                                </li>
                                <li>
                                    <h3>6<span>Days</span></h3>
                                    <label class="missed">Missed</label>
                                </li>
                            </ul>
                        </div>
                        <div class="overview">
                            <template v-for="(progress, p) of ProgressScenarioList[selectScenario].categories" :key="p">
                                <ul>
                                    <template v-for="(item, i) of progress.items" :key="i">
                                        <li v-if="item.is_selected" @click="scrollingTo(`${item.title.replaceAll(' ','')}-${i}`)" >
                                            <img :src="require(`@/assets/images/${item.img}`)" :alt="item.name">
                                            <h4>
                                                {{item.title}}
                                                <span v-if="item.average_score">Average <a>{{item.average_score}} {{item.unit.value }}</a> per day</span>
                                                <span v-else>Not available</span>
                                            </h4>
                                        </li>
                                    </template>
                                </ul>
                            </template>
                        </div>
                    </div>
                    
                    <template v-for="(progress, p) of ProgressScenarioList[selectScenario].categories" :key="p">
                        <div v-for="(item, i) of progress.items" :key="i" :id="`${item.title.replaceAll(' ','')}-${i}`">
                            <track-chart :option="item" v-if="item.is_selected"></track-chart>
                        </div>
                    </template>

                    <div class="content_card_wpr show" id="progress_history">
                        <div class="title">
                            <h4>Completed Trackings<label>12</label></h4>
                            <div class="rate">
                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;">
                                    <div class="inner_circle">
                                        <div class="score">50<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="57" />
                                    </svg>
                                </div>
                                <h6>Completion rate</h6>
                            </div>
                            <button type="button" class="toggle_btn" @click="toggleFunc"><i class="fas fa-chevron-down"></i></button>
                        </div>
                        <div class="section_content">
                            <ul class="list workout_card workout_list">
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                            <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 24, 2023</span> Circumference<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <ul class="field_list">
                                                        <li>
                                                            <label>Neck</label>
                                                            <div class="submitted_data">19<span>inches</span></div>
                                                        </li>
                                                        <li>
                                                            <label>Shoulders</label>
                                                            <div class="submitted_data">21<span>inches</span></div>
                                                        </li>
                                                        <li>
                                                            <label>Biceps</label>
                                                            <div class="submitted_data">15<span>inches</span></div>
                                                        </li>
                                                        <li>
                                                            <label>Chest</label>
                                                            <div class="submitted_data">47<span>inches</span></div>
                                                        </li>
                                                    </ul>
                                                    <div class="submit_details">
                                                        <span>Submitted On Time</span>
                                                        <label>Already submitted on Sept 24, 2023 at 9:00 AM</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                            <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 25, 2023</span> Body Fat<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <div class="field_item">
                                                        <div class="submitted_data">37<span>%</span></div>
                                                    </div>
                                                    <div class="submit_details">
                                                        <span class="delay">Submitted Late</span>
                                                        <label>Already submitted on Sept 29, 2023 at 7:00 AM</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                            <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 26, 2023</span> Skeletal Muscle<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <div class="field_item">
                                                        <div class="submitted_data">37<span>kg</span></div>
                                                    </div>
                                                    <div class="submit_details">
                                                        <span class="success">Edited After Submission</span>
                                                        <label>Already submitted on Sept 27, 2023 at 9:00 AM</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="content_card_wpr show">
                        <div class="title">
                            <h4>Missed Trackings<label>12</label></h4>
                            <div class="rate">
                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;">
                                    <div class="inner_circle">
                                        <div class="score">50<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="57" />
                                    </svg>
                                </div>
                                <h6>Completion rate</h6>
                            </div>
                            <button type="button" class="toggle_btn" @click="toggleFunc"><i class="fas fa-chevron-down"></i></button>
                        </div>
                        <div class="section_content">
                            <ul class="list workout_card workout_list">
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                            <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 27, 2023</span> Circumference<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <ul class="field_list">
                                                        <li>
                                                            <label>Neck</label>
                                                            <div class="field_area has_suffix">
                                                                <input type="number" placeholder="ex: 37">
                                                                <span class="suffix">inches</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <label>Shoulders</label>
                                                            <div class="field_area has_suffix">
                                                                <input type="number" placeholder="ex: 37">
                                                                <span class="suffix">inches</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <label>Biceps</label>
                                                            <div class="field_area has_suffix">
                                                                <input type="number" placeholder="ex: 37">
                                                                <span class="suffix">inches</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <label>Chest</label>
                                                            <div class="field_area has_suffix">
                                                                <input type="number" placeholder="ex: 37">
                                                                <span class="suffix">inches</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <div class="submit_details">
                                                        <span class="missed">Missed</span>
                                                        <a class="redirect_btn missed" href="https://reachvine.com/ef08d0" target="_blank">Submit report late <i class="fas fa-long-arrow-alt-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                            <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 28, 2023</span> Body Fat<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <div class="field_item">
                                                        <div class="field_area has_suffix">
                                                            <input type="number" placeholder="ex: 37">
                                                            <span class="suffix">%</span>
                                                        </div>
                                                    </div>
                                                    <div class="submit_details">
                                                        <span class="missed">Missed</span>
                                                        <a class="redirect_btn missed" href="https://reachvine.com/ef08d0" target="_blank">Submit report late <i class="fas fa-long-arrow-alt-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="card_wrapper">
                                        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                            <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                        </svg>
                                        <div class="info_wpr">
                                            <h4 @click="showDetails($event)"><span class="date">Sep 29, 2023</span> Skeletal Muscle<i class="fas fa-chevron-down"></i></h4>
                                            <div class="details">
                                                <div class="blocks">
                                                    <div class="field_item">
                                                        <div class="field_area has_suffix">
                                                            <input type="number" placeholder="ex: 37">
                                                            <span class="suffix">kg</span>
                                                        </div>
                                                    </div>
                                                    <div class="submit_details">
                                                        <span class="missed">Missed</span>
                                                        <a class="redirect_btn missed" href="https://reachvine.com/ef08d0" target="_blank">Submit report late <i class="fas fa-long-arrow-alt-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'

const TrackChart = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/TrackChart'))

export default {
    name: 'Workouts Stats Details',

    data () {
        return{
            progressDuration: false,
            selectProgressFilter: 'Last 7 Days',
            progressFilterDuration:[
                'Last 7 Days',
                'Last 14 Days',
                'Last 30 Days',
                'Last 90 Days',
                'This Year',
                'All Time',
            ],
            openFilter: false,
            scenarioDropdown: false,
            ProgressScenarioList: [
                {
                    title: 'Body Composition Test',
                    categories:[
                        {
                            group_title: 'Body Composition',
                            items:[
                                {
                                    name: 'Weight',
                                    is_selected: 1,
                                    img: 'weight.svg',
                                    title: 'Weight',
                                    subtext: 'What\'s your weight today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'kg',
                                        options: [
                                            { value: 'kg', label: 'Kilograms' },
                                            { value: 'lbs', label: 'Pounds' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Body Fat',
                                    is_selected: 1,
                                    img: 'body_fat.svg',
                                    title: 'Body Fat',
                                    subtext: 'What\'s your body fat percentage today?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 28, 29, 27, 32, 30, 35, 33, 32, 30, 28, 29, 31, 29, 30, 28, 30, 32, 35, 33, 31, 30, 28, 27, 25, 23, 21, 23, 21, 20],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 30},
                                                    {name: 'Min', yAxis: 20},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Circumference',
                                    is_selected: 1,
                                    img: 'measurement.svg',
                                    title: 'Circumference',
                                    subtext: 'What are your circumference measurements today?',
                                    unit: {
                                        mode: 'single',
                                        value: 'in',
                                        options: [
                                            { value: 'in', label: 'Inches' },
                                            { value: 'cm', label: 'Centimeters' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: 'Neck',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 1,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 40},
                                                    {name: 'Min', yAxis: 30},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 40 },
                                                        { yAxis: 30 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Shoulder',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.80,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 35},
                                                    {name: 'Min', yAxis: 25},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 35 },
                                                        { yAxis: 25 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Biceps',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.60,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 25},
                                                    {name: 'Min', yAxis: 15},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Chest',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 1,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 40},
                                                    {name: 'Min', yAxis: 30},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 40 },
                                                        { yAxis: 30 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Belly Button',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.80,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 35},
                                                    {name: 'Min', yAxis: 25},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 35 },
                                                        { yAxis: 25 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Waist',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.60,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 25},
                                                    {name: 'Min', yAxis: 15},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Hips',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 1,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 40},
                                                    {name: 'Min', yAxis: 30},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 40 },
                                                        { yAxis: 30 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Thigh',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.80,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 35},
                                                    {name: 'Min', yAxis: 25},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 35 },
                                                        { yAxis: 25 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        },
                                        {
                                            name: 'Calf',
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'insideBottom',
                                                distance: 10,
                                                opacity: 1,
                                                formatter: function(params) {
                                                    return `${params.value}`;
                                                }
                                            },
                                            data: [25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30],
                                            itemStyle: {
                                                color: '#2f7eed',
                                                opacity: 0.60,
                                            },
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                symbolOffset: [0, -15],
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 25},
                                                    {name: 'Min', yAxis: 15},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 0,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 0,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Fat-free Mass',
                                    is_selected: 1,
                                    img: 'fat-free-mass.svg',
                                    title: 'Fat-free Mass',
                                    subtext: 'How much fat-free mass do you have?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 28, 29, 27, 32, 30, 35, 33, 32, 30, 28, 29, 31, 29, 30, 28, 30, 32, 35, 33, 31, 30, 28, 27, 25, 23, 21, 23, 21, 20],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 30},
                                                    {name: 'Min', yAxis: 20},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Skeletal Muscle',
                                    is_selected: 1,
                                    img: 'skeletal-mass.svg',
                                    title: 'Skeletal Muscle',
                                    subtext: 'How much skeletal muscle do you have?',
                                    unit: {
                                        mode: 'single',
                                        value: 'kg',
                                        options: [
                                            { value: 'kg', label: 'Kilograms' },
                                            { value: 'lbs', label: 'Pounds' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Body Water',
                                    is_selected: 1,
                                    img: 'body-water.svg',
                                    title: 'Body Water',
                                    subtext: 'How much body water do you have?',
                                    unit: {
                                        mode: 'single',
                                        value: 'L',
                                        options: [
                                            { value: 'l', label: 'Liter' },
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 55},
                                                    {name: 'Min', yAxis: 35},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Visceral Fat',
                                    is_selected: 1,
                                    img: 'visceral_fat.svg',
                                    title: 'Visceral Fat',
                                    subtext: 'How much visceral fat do you have?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 28, 29, 27, 32, 30, 35, 33, 32, 30, 28, 29, 31, 29, 30, 28, 30, 32, 35, 33, 31, 30, 28, 27, 25, 23, 21, 23, 21, 20],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 30},
                                                    {name: 'Min', yAxis: 20},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                                {
                                    name: 'Bone Mass',
                                    is_selected: 1,
                                    img: 'bone_mass.svg',
                                    title: 'Bone Mass',
                                    subtext: 'How much bone mass do you have?',
                                    unit: {
                                        mode: 'single',
                                        value: '%',
                                        options: [
                                            { value: '%', label: 'Percentage' }
                                        ],
                                    },
                                    isGoalRange: 1,
                                    chart: [
                                        {
                                            name: '',
                                            type: 'line',
                                            smooth: true,
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            lineStyle: {
                                                color: {
                                                    type: 'linear',
                                                    x: 0,
                                                    y: 0,
                                                    x2: 0,
                                                    y2: 1,
                                                    colorStops: [
                                                        {
                                                            offset: 0,
                                                            color: '#2F7FED'
                                                        },
                                                        {
                                                            offset: 1,
                                                            color: '#88CBEE'
                                                        }
                                                    ],
                                                    global: false
                                                }
                                            },
                                            data: [30, 28, 29, 27, 32, 30, 35, 33, 32, 30, 28, 29, 31, 29, 30, 28, 30, 32, 35, 33, 31, 30, 28, 27, 25, 23, 21, 23, 21, 20],
                                            markPoint: {
                                                symbol: 'circle',
                                                symbolSize: 20,
                                                label: {
                                                    fontSize: 10,
                                                    color: '#fff'
                                                },
                                                data: [],
                                            },
                                            markLine: {
                                                data: [
                                                    {name: 'Max', yAxis: 30},
                                                    {name: 'Min', yAxis: 20},
                                                ],
                                                label:{
                                                    position: 'end',
                                                },
                                                lineStyle:{
                                                    opacity: 1,
                                                    width: 0,
                                                },
                                                symbol: 'none',
                                            },
                                            markArea: {
                                                data: [
                                                    [
                                                        { yAxis: 55 },
                                                        { yAxis: 35 }
                                                    ],
                                                ],
                                                itemStyle: {
                                                    color: 'rgba(47,127,237,0.08)',
                                                    opacity: 1,
                                                },
                                            }
                                        }
                                    ],
                                },
                            ]
                        }
                    ]
                },
            ],
            selectScenario: 0,
            allAttr: 1,
        }
    },

    components:{
        TrackChart
    },

    props: {
        modelValue: Boolean,
    },

    emit: ['update:modelValue'],

    computed: {
        ...mapState({
            user: state => state.authModule.user,
        }),
    },
    
    watch: {
        modelValue (value) {
            if (value) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },
    },

    methods:{
        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },
        toggleFunc(ev){
            let el = ev.currentTarget.closest('.content_card_wpr');
            const subEl = el.querySelectorAll('.list li');
            
            if (el.classList.contains('show')){
                el.classList.remove('show');
                for(let i = 0; i < subEl.length; i++){
                    subEl[i].classList.remove('show');
                }
            } else {
                el.classList.add('show');
            }
        },
        showDetails(ev){
            const el = ev.currentTarget.closest('li');
            if(el.classList.contains('show')){
                el.classList.remove('show');
            }else{
                el.classList.add('show');
            }
        },
        closeProgressDuration(){
            const vm = this;
            vm.progressDuration = false;
        },
        closeScenarioDropdown(){
            const vm = this;
            vm.scenarioDropdown = false;
        },
        closeOpenFilter(){
            const vm = this;
            vm.openFilter = false;
        },
        scrollingTo (el) {
            let targetElm = document.querySelector(`.preview_area .tabs_content #${el}`);

            setTimeout(function() {
                if (targetElm && targetElm.offsetTop) {
                    let top = targetElm.offsetTop - 50;
                    document.querySelector('.preview_area .tabs_content').scrollTo(0, top);
                }
            }, 100);
        },
    }
}
</script>

<style scoped>
.stat_container{
    max-width: 1000px;
    padding: 0 20px;
    margin: 0 auto;
}
.section_header h2{
    margin-right: auto;
}
.section_header h2 .tag{
    background: #e9e9e9;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    margin-left: 10px;
}
.section_header .optionDrops {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: left;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}
.section_header .optionDrops i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}
.section_header .optionDrops .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
    overflow: hidden;
}
.section_header .optionDrops ul li {
    flex: 1 1 auto;
    border: 0;
    background: transparent;
}
.report_card {
    border-radius: 5px;
    margin-bottom: 40px;
    background: #fff;
}
.report_card .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #e9e9e9;
}
.report_card .user_info {
    display: flex;
    text-align: left;
    align-items: center;
}
.report_card .user_info img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
    border: 1px solid #ccc;
}
.report_card .header h4 {
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 3px;
}
.report_card .header h5 {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #5a5a5a;
    margin-bottom: 0;
}
.report_card .total_progress{
    display: flex;
    flex-wrap: wrap;
}
.report_card .total_progress li{
    margin-left: 20px;
    display: flex;
    align-items: flex-end;
}
.report_card .total_progress label{
    padding: 2px 8px;
    display: block;
    border-radius: 12px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    margin: 0 0 0 5px;
}
.report_card .total_progress label.completed{
    background: #e8fdea;
    color: #30C13E;
}
.report_card .total_progress label.missed{
    background: #ffefef;
    color: #fb1414;
}
.report_card .total_progress h3{
    font-size: 20px;
    line-height: 25px;
    font-weight: 300;
    color: #121525;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
}
.report_card .total_progress h3 span{
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #5a5a5a;
    margin: 0 0 2px 5px;
}
.report_card .overview{
    background: #fff;
    border-radius: 5px;
}
.overview ul{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}
.overview ul li{
    padding: 15px;
    width: 33.333%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    cursor: pointer;
}
.overview li h4 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.overview li h4 span{
    font-size: 12px;
    line-height: 15px;
    font-weight: 300;
    color: #666;
    padding-top: 4px;
}
.overview li h4 span a{
    color: #121525;
    font-weight: 500;
}
.overview li img{
    max-width: 50px;
    height: auto;
    padding-right: 15px;
}
.overview li .score{
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}
.overview li .score span{
    font-size: 8px;
}
.progress_filter_bar{
    width: 190px;
    min-height: 240px;
}
.progress_filter_bar::-webkit-scrollbar{
    display: none;
}
.progress_filter_bar.show{
    right: 0;
}
.progress_filter_bar h4{
    font-size: 15px;
    line-height: 22px;
    color: #121525;
    font-weight: 500;
    padding: 0px 20px 5px;
    display: flex;
    align-items: center;
}
.progress_filter_bar h4 .close_btn{
    font-size: 13px;
    color: #999999;
    margin-left: auto;
    cursor: pointer;
}
.progress_filter_bar .scenario_box{
    width: auto;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    position: relative;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #121525;
    padding: 8px 25px 8px 10px;
    margin: 15px;
    cursor: pointer;
}
.progress_filter_bar .scenario_box i{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #eeeeee;
    font-size: 13px;
    color: #999;
}
.progress_filter_bar .toggle_btn{
    display: flex;
    justify-content: flex-end;
    padding: 6px 20px 12px;
}
.progress_filter_bar .toggle_btn a{
    padding: 4px 10px;
    background: rgb(245, 245, 245);
    font-size: 10px;
    line-height: 12px;
    color: rgb(18, 21, 37);
    font-weight: 500;
    display: block;
    border-radius: 10px;
    cursor: pointer;
}
.progress_filter_bar .category_wpr{
    max-height: 400px;
    overflow-y: auto;
}
.progress_filter_bar .category_wpr::-webkit-scrollbar{
    width: 4px;
}
.progress_filter_bar .category_wpr::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 2px;
}
.progress_filter_bar .category_wpr .category_grp{
    border-top: 1px solid #f5f5f5;
}
.progress_filter_bar .category_wpr .category_grp h4{
    padding: 12px 15px;
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.progress_filter_bar .category_wpr .category_grp h4 i{
    margin-left: auto;
    font-size: 11px;
    color: #999999;
    transition: all 0.3s ease-in-out;
}
.progress_filter_bar .category_wpr .category_grp ul{
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}
.progress_filter_bar .category_wpr .category_grp.active ul{
    max-height: 10000px;
}
.progress_filter_bar .category_wpr ul li{
    padding: 10px 0;
    border-radius: 5px;
}
.progress_filter_bar .category_wpr ul li label.checkbox{
    justify-content: flex-start;
}
.progress_filter_bar .category_wpr ul li label.checkbox h5{
    font-size: 11px;
    line-height: 15px;
    color: #121525;
    font-weight: 500;
    padding-left: 10px;
}
.progress_filter_bar .category_wpr ul li label.checkbox span i{
    font-size: 10px;
    margin: 0;
}
.progress_filter_bar .category_wpr ul li label.checkbox span.active i{
    transform: scale(1);
    opacity: 1;
}

.content_card_wpr{
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.03);
    background: #fff;
    margin-bottom: 30px;
    overflow: hidden;
}
.content_card_wpr .title{
    font-size: 18px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
}
.content_card_wpr .title span{
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: #777;
    margin-left: auto;
}
.content_card_wpr .title h4{
    font-size: 16px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.content_card_wpr .title h4 i{
    font-size: 14px;
    color: #2f7eed;
}
.content_card_wpr .title h4 label{
    font-size: 11px;
    line-height: 20px;
    border-radius: 12px;
    color: #2f7eed;
    background: #e3eefd;
    font-weight: 500;
    display: inline-block;
    padding: 0 6px;
}
.content_card_wpr .title .rate{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
}
.content_card_wpr .title .rate .score, .tracking_list li .score{
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}
.content_card_wpr .title .rate .score span, .tracking_list li .score span{
    font-size: 8px;
}

.content_card_wpr .title .rate h6{
    font-size: 9px;
    line-height: 10px;
    color: #5a5a5a;
    font-weight: 400;
    margin-top: 5px;
    max-width: 100px;
    text-align:center;
    display: none;
}
.content_card_wpr .title .toggle_btn{
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #eee;
    font-size: 10px;
    color: #5a5a5a;
    margin-left: 15px;
    transition: all 0.5s ease-in-out;
}
.content_card_wpr.show .title .toggle_btn{
    transform: rotate(-180deg);
}
.content_card_wpr .section_content{
    padding: 0;
    width: 100%;
    max-height: 55px;
    transition: all 0.5s ease-in-out;
}
.content_card_wpr.show .section_content{
    max-height: 1000vh;
    overflow: overlay;
}
.content_card_wpr.show .section_content::-webkit-scrollbar{
    width: 3px;
}
.content_card_wpr.show .section_content::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 2px;
}
.content_card_wpr.show .section_content {
    max-height: 1000vh;
    overflow: overlay;
}

.section_content .list > li{
    display: flex;
    padding: 0 30px;
    min-height: 55px;
    /* border-bottom: 1px solid #f5f5f5; */
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
}
.workout_card li .info_wpr{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    position: relative;
    padding-bottom: 15px;
    gap: 0;
    transition: all 0.3s ease-in-out;
}
.workout_card li.show .info_wpr{
    gap: 15px;
}
.workout_card li.show .info_wpr h4 i{
    transform: rotate(-180deg);
}
.workout_card li a{
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2f7eed;
    margin: 0 0 0 auto;
    cursor: pointer;
}
.workout_card li.show .info_wpr .details{
    max-height: 1000px;
}
.workout_card li:last-child .info_wpr{
    border-bottom: 0;
}
.workout_card li .card_wrapper{
    border-radius: 6px;
    display: flex;
    gap: 15px;
    cursor: pointer;
    padding: 15px 0 0 0;
    width: 100%;
}
.workout_card li .card_wrapper svg{
    flex: 0 0 20px;
    height: 20px;
}
.workout_card li .info_wpr h4{
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.workout_card li .info_wpr h4 .date{
    font-weight: 500;
    color: #121525;
    padding-right: 10px;
}
.workout_card li .info_wpr span.tag{
    background: #ecf4ff;
    padding: 0 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    color: #2f7eed;
    margin-left: 6px;
}
.workout_card li .info_wpr h4 i{
    font-size: 10px;
    margin-left: auto;
    color: #999;
    transition: all 0.3s ease-in-out;
    display: none;
}
.content_card_wpr.show .workout_card li .info_wpr h4 i{
    display: block;
}
.workout_card li .info_wpr h6{
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -5px 5px -5px; */
    gap: 5px;
}
.workout_card li .info_wpr h6 span{
    padding: 0 5px 0 0;
}
.workout_card li .info_wpr h6 span:not(:last-child){
    border-right: 1px solid #d9d9d9;
}
.workout_card li .info_wpr .blocks{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 10px;
    flex: 1 1 auto;
}
.workout_card li .info_wpr .field_list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    max-width: 600px;
}
.workout_card li .info_wpr .field_list li{
    width: 25%;
    padding: 10px;
}
.workout_card li .info_wpr .field_list label{
    display: block;
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 400;
    padding-bottom: 5px;
}
.workout_card li .info_wpr .field_area{
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
}
.workout_card li .info_wpr .field_area input {
    height: 40px;
    width: 100%;
    padding: 0 5px 0 10px;
    font-size: 11px;
    color: #5a5a5a;
    background: transparent;
}
.workout_card li .info_wpr .field_area .suffix{
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 40px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}
.workout_card li .info_wpr .field_item{
    margin: 10px 0;
    max-width: 250px;
}
.workout_card li .info_wpr .submitted_data{
    padding-top: 5px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: #121525;
}
.workout_card li .info_wpr .submitted_data span{
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #5a5a5a;
    padding-left: 5px;
}
.workout_card li .info_wpr .todo_list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
}
.workout_card li .info_wpr .todo_list li{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #5a5a5a;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}
.workout_card li .info_wpr .todo_list li label {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    color: #777;
    font-size: 9px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin-top: 2px;
}
.workout_card li .info_wpr .todo_list li .task_ans{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}
.workout_card li .info_wpr .todo_list li .task_ans .yes_no_box{
    display: flex;
    gap: 7px;
}
.workout_card li .info_wpr .todo_list li .task_ans .yes_no_box.disabled{
    opacity: 0.5;
}
.workout_card li .info_wpr .todo_list li .task_ans .yes_box{
    width: 60px;
    border: 1px solid #b2eabf;
    background: #effff2;
    color: #23993e;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
}
.workout_card li .info_wpr .todo_list li .task_ans .yes_box i{
    font-size: 10px;
    margin-top: 1px;
}
.workout_card li .info_wpr .todo_list li .task_ans .no_box{
    width: 60px;
    border: 1px solid #ffb4b4;
    background: #ffecec;
    color: #eb1414;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
}
.workout_card li .info_wpr .todo_list li .task_ans .no_box i{
    font-size: 10px;
    margin-top: 3px;
}
.workout_card li .info_wpr .todo_list li .task_ans .ans_text{
    min-width: 50px;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
}


.workout_card li .info_wpr .details {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    gap: 15px;
    transition: all 0.3s ease-in-out;
}
.workout_card li .info_wpr .details .redirect_btn{
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 0 0 auto;
    text-decoration: none;
}
.workout_card li .info_wpr .details .redirect_btn.missed{
    color: #eb1414;
}
.workout_card li .info_wpr .details .submit_details{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.workout_card li .info_wpr .details .submit_details label{
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
}
.workout_card li .info_wpr .details .submit_details span{
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #2f7eed;
    background: #f0f6ff;
    padding: 4px 10px;
    border-radius: 12px;
}
.workout_card li .info_wpr .details .submit_details span.delay{
    color: #f2a31d;
    background: #fff6e6;
}
.workout_card li .info_wpr .details .submit_details span.success{
    color: #23993e;
    background: #effff2;
}
.workout_card li .info_wpr .details .submit_details span.missed{
    color: #eb1414;
    background: #fff2f2;
}
.workout_card li.show .info_wpr .details{
    max-height: 1000px;
}

@media(max-width: 767px){
    .report_card .header{
        padding: 20px;
    }
    .report_card .header h3 {
        font-size: 13px !important;
        line-height: 17px !important;
        display: block;
    }
}
@media(max-width: 499px){
    .report_card .header h3 span{
        display: block;
        font-size: 11px;
        line-height: 15px;
    }
    .stat_container .score_circle {
        transform: scale(1);
    }
}
</style>